'use strict';

/*******************************************************************************************/
class UxrPopupCluster20 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			'entries': {},
		};
		
		this.onJQueryMount = this.onJQueryMount.bind(this);
		this.onClick_listEntry = this.onClick_listEntry.bind(this);
		
		GLOBALS.clusterReact = this;
	}
	
	/*---------------------------------------------------------------------*/
	render() {
		let self = this;
		
		let props = {};
		props['ref'] = this.onJQueryMount;
		
		let svgMarkerMarkup = (
			<img className="hitListMarker hitListMarkerEvent" src="/application/themes/rememberingKlassisch/img/marker_ereignis_full.png" />
		);
		
		let hitList = [];
		let streetname = null;
		_.forEach(this.state.entries, function(p, id) {
			let props = {
				'key': id,
				'data-id': id,
				'onClick': self.onClick_listEntry,
			}
			hitList.push(
				<div className="hitListEntry" {...props}>
					{svgMarkerMarkup}
					<span>{p.title}</span>
					<div className="flowClear" />
				</div>
			);
			if (!streetname) {
				streetname = p.marker.streetname;
			}
		});
		
		return (
			<div className="popupContainer popupContainerList" {...props}>
				<div className="popupContainer_controls">
					<div className="popupContainer_iconClose fa fa-close"></div>
				</div>
				
				<div className="popupContainer_content">
					<h2 className="title">{streetname}</h2>
					
					<div className="content">
						<div className="content_list active">
						<div className="flowRight writeCaps"></div>
						<div className="flowClear"></div>
							<div className="scrollable">
								{hitList}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
	
	/*---------------------------------------------------------------------*/
	onJQueryMount(node) {
		this.jQ = jQuery(node);
		
		GLOBALS.clusterSO = new POPUPCONTAINER_SO_2_0(
			this.jQ,
			GLOBALS.popupSpaceSupplier, 
			GLOBALS.stateEngine.markerHide,
			POPUPCONTAINER_VO_LIST_2_0
		);
		GLOBALS.spaceObjects.push(GLOBALS.clusterSO);
	}
	
	/*---------------------------------------------------------------------*/
	onClick_listEntry(event) {
		var node = jQuery(event.target).closest(".hitListEntry[data-id]");
		var id = parseInt(node.attr('data-id'));
		GLOBALS.stateEngine.contentShow(id);
	}
} //class

